import { FC, MouseEvent } from 'react';
import Proptypes from 'prop-types';
import { format } from 'date-fns';
import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import ImageNotSupportedIcon from '@material-ui/icons/ImageNotSupported';
import { Job, EJobResultCode } from '../../../../types/job';
import { Pagination } from '../pagination';
import { Ban as DeactivateItemIcon } from '../../../../icons/ban';
import { ResourceError } from '../../../../components/resource-error';
import { ResourceUnavailable } from '../../../../components/resource-unavailable';
import { JobsStatusCell } from './jobs-status-cell';
import { getAssetUrl } from '../../../../utils/assets-helper';
import { EAssetTitle } from '../../../../types/asset';
import { Sort } from '../../../../types/filter';

interface JobsTableProps {
  error: string;
  isLoading: boolean;
  onPageChange: (newPage: number) => void;
  onSortChange: (event: MouseEvent<HTMLElement>, property: string) => void;
  page: number;
  jobs: Job[];
  jobsCount: number;
  sort: Sort;
  sortBy: string;
}

const columns = [
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'item',
    label: 'Item',
  },
  {
    id: 'createdAt',
    label: 'Date',
  },
];

export const JobsTable: FC<JobsTableProps> = (props) => {
  const { error, isLoading, onPageChange, onSortChange, page, jobs, jobsCount, sort, sortBy } =
    props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !jobs.length);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Table id="activity-table" stickyHeader sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>
                <TableSortLabel
                  active={sortBy === column.id}
                  direction={sortBy === column.id ? sort : 'asc'}
                  disabled
                  onClick={(event) => onSortChange(event, column.id)}
                  sx={{
                    background: 'transparent',
                  }}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map((job, index) => (
            <TableRow
              id={`activity-table-row-${index}`}
              key={job.id}
              sx={{
                background: job?.resultCode === EJobResultCode.NOT_VERIFIED ? '#FFE6E7' : 'initial',
                height: 'calc((100vh - 342px) / 5)',
                '.MuiTableCell-root': {
                  py: 0,
                },
              }}
            >
              {/* Status */}
              <TableCell
                sx={{
                  width: 420,
                }}
              >
                <JobsStatusCell job={job} elementId={`activity-table-row-${index}-job-status`} />
              </TableCell>

              {/* Item */}
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    alt={job.item?.title}
                    src={getAssetUrl(job.item, EAssetTitle.FRONT_THUMBNAIL)}
                    sx={{
                      width: 129,
                      height: 129,
                      boxShadow: '0 0 1px black',
                    }}
                    variant="rounded"
                  >
                    <ImageNotSupportedIcon fontSize="small" />
                  </Avatar>
                  <Box sx={{ ml: 2 }}>
                    <Box>
                      <Box display="flex">
                        {job.item?.publicMetadata?.deactivated && (
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{
                              mr: 1,
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                            title="Deactivated item"
                          >
                            <DeactivateItemIcon />
                          </Typography>
                        )}
                        <Typography
                          className="job-item-unique-id"
                          variant="subtitle1"
                          sx={{
                            fontWeight: 600,
                          }}
                        >
                          {job.item?.publicMetadata?.uniqueId}
                        </Typography>
                      </Box>
                      <Typography
                        id={`activity-table-row-${index}-sku-item-title`}
                        color="textSecondary"
                        variant="body1"
                      >
                        {job.item?.sku?.title} • {job.item?.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </TableCell>

              {/* Verified */}
              <TableCell
                sx={{
                  width: 390,
                }}
              >
                <Box
                  className="job-started-at"
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {Boolean(job?.startedAt || job?.createdAt) && (
                    <>
                      <Typography color="inherit" variant="body2">
                        {format(new Date(job.startedAt || job.createdAt), 'dd MMM yyyy')}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {format(new Date(job.startedAt || job.createdAt), 'h:mm:ssaaa')}
                      </Typography>
                    </>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          text="No Activity yet."
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={jobsCount}
      />
    </Box>
  );
};

JobsTable.defaultProps = {
  page: 1,
  jobs: [],
  jobsCount: 0,
  sort: 'desc',
  sortBy: 'createdAt',
};

JobsTable.propTypes = {
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  jobs: Proptypes.array,
  jobsCount: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
};
