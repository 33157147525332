import { Asset, CreateAssetData } from './asset';
import { Fingerprint } from './fingerprint';
import { SKU } from './sku';
import { User } from './user';

export interface ItemMetadata {
  widthCm?: number;
  heightCm?: number;

  // eBay
  uniqueId?: string;
  deactivated?: boolean;

  // Others
  [key: string]: any;
}

export enum EItemVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  TENANT = 'TENANT',
}

interface BaseItem {
  title: string;
  publicMetadata?: ItemMetadata;
  // Debug response for jobs
  debug?: string;
  // Debug images for camera controller
  debugImages?: string[];
}

export interface BaseItemWithSKU extends BaseItem {
  sku: SKU;
}

export interface CreateBaseItemData extends BaseItem {
  subtitle?: string;
  externalId?: string;
  metadata?: ItemMetadata;
  visibility?: EItemVisibility;
  assets?: CreateAssetData[];
}

export interface CreateItemData {
  item: CreateBaseItemData;
  tenantId: string;
  skuId?: string;
  verticalId?: string;
  workerQueury?: string;
}

export interface Item extends BaseItemWithSKU {
  id: string;
  subtitle?: string | null;
  assets: Asset[];
  uniqueId: string;
  externalId?: string | null;
  tenantId: string;
  fingerprints: Fingerprint[];
  createdAt: Date;
  updatedAt: Date;
  owner: User;
  isProtected: boolean;
  protectedBy?: User;
  protectedAt?: Date;
  isRegistered: boolean;
  registeredBy?: User;
  registeredAt?: Date;
  visibility?: EItemVisibility;
}
