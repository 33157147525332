import type { FC, MouseEvent } from 'react';
import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Avatar,
  Box,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import ImageNotSupportedIcon from '@material-ui/icons/ImageNotSupported';
import { Ban as DeactivateItemIcon } from '../../../../icons/ban';
import { EJobResultType, EJobTitle, Job } from '../../../../types/job';
import { Pagination } from '../../../../components/pagination';
import { ResourceError } from '../../../../components/resource-error';
import { ResourceUnavailable } from '../../../../components/resource-unavailable';
import { Scrollbar } from '../../../../components/scrollbar';
import { JobStatus } from '../../../../components/job/job-status';
import { useAuth } from '../../../../hooks/use-auth';
import { getJobRunningStatus, getResultStatus } from '../../../../utils/jobs-helper';
import { getThumbnailUrl } from '../../../../utils/assets-helper';
import { JobsMenu } from '../../../../components/job/jobs-menu';
import { Sort } from '../../../../types/filter';

interface JobsTableProps {
  error: string;
  isLoading: boolean;
  onPageChange: (newPage: number) => void;
  onSortChange: (event: MouseEvent<HTMLElement>, property: string) => void;
  page: number;
  jobs: Job[];
  jobsCount: number;
  sort: Sort;
  sortBy: string;
}

const columns = [
  {
    id: 'id',
    label: 'Event ID',
    sortable: false,
  },
  {
    id: 'item.title',
    label: 'Item',
    sortable: false,
  },
  {
    id: 'item.sku',
    label: 'SKU Title',
    sortable: false,
  },
  {
    id: 'user',
    label: 'User',
  },
  {
    id: 'createdAt',
    label: 'Date Time',
  },
  {
    id: 'status',
    label: 'Status',
    sortable: false,
  },
  {
    id: 'time',
    label: 'Time',
    sortable: false,
  },
];

const getRowColor = (job: Job) => {
  const status = getResultStatus(job);
  const isRunning = getJobRunningStatus(job);
  if (
    !isRunning &&
    (status === EJobResultType.NOT_VERIFIED ||
      (status === EJobResultType.FAILED &&
        (job.title === EJobTitle.PROTECTION || job.title === EJobTitle.REGISTRATION)))
  ) {
    return '#FFE5E7';
  }
  return 'unset';
};

export const JobsTable: FC<JobsTableProps> = (props) => {
  const { error, isLoading, onPageChange, onSortChange, page, jobs, jobsCount, sort, sortBy } =
    props;

  const { tenant } = useAuth();

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !jobs.length);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading || column.sortable === false}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => {
              const isEventIdentification =
                job.title === EJobTitle.REGISTRATION ||
                job.title === EJobTitle.IDENTIFICATION ||
                job.title === EJobTitle.DELETE_REGISTRATION;

              const enableEventResultPage = job.title !== EJobTitle.DELETE_REGISTRATION;

              return (
                <TableRow
                  hover
                  key={job.id}
                  sx={{
                    backgroundColor: getRowColor(job),
                  }}
                >
                  {/* Event ID */}
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      {enableEventResultPage ? (
                        <Link
                          color="inherit"
                          component={RouterLink}
                          sx={{ display: 'block' }}
                          to={
                            job.item && isEventIdentification
                              ? `/tenants/${tenant?.id}/skus/${
                                  job.sku?.id ?? job.item?.sku?.id
                                }/job/${job.id}?source=jobs`
                              : `/tenants/${tenant?.id}/items/${job.item?.id}/job/${job.id}?source=jobs`
                          }
                          variant="subtitle2"
                        >
                          <Typography
                            variant="subtitle1"
                            noWrap
                            textOverflow="ellipsis"
                            maxWidth={{
                              xs: 100,
                              xl: 345,
                            }}
                            sx={{
                              direction: 'rtl',
                            }}
                            title={job.id}
                          >
                            {job.id}
                          </Typography>
                        </Link>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          noWrap
                          textOverflow="ellipsis"
                          maxWidth={{
                            xs: 100,
                            xl: 345,
                          }}
                          sx={{
                            direction: 'rtl',
                          }}
                          title={job.id}
                        >
                          {job.id}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>

                  {/* Item Title + Item UID */}
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Avatar
                        alt={job.item?.title || job.sku?.title || job.item?.sku?.title}
                        src={getThumbnailUrl(job.item || job.sku)}
                        sx={{
                          width: 64,
                          height: 64,
                          '& > img': {
                            objectFit: 'contain',
                          },
                        }}
                        variant="rounded"
                      >
                        <ImageNotSupportedIcon fontSize="small" />
                      </Avatar>

                      <Box sx={{ ml: 2 }}>
                        <Link
                          color="inherit"
                          component={RouterLink}
                          sx={{ display: 'block' }}
                          to={
                            job.item
                              ? `/tenants/${tenant?.id}/items/${job.item?.id}/timeline?source=jobs#${job.id}`
                              : `/tenants/${tenant?.id}/skus/${job.sku?.id}/timeline?source=jobs#${job.id}`
                          }
                          underline="none"
                          variant="subtitle2"
                        >
                          {job.item ? (
                            <Box>
                              <Box display="flex">
                                {job.item?.publicMetadata?.deactivated && (
                                  <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                    sx={{
                                      mr: 1,
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                    }}
                                    title="Deactivated item"
                                  >
                                    <DeactivateItemIcon />
                                  </Typography>
                                )}
                                <Typography
                                  variant="subtitle1"
                                  noWrap
                                  maxWidth={{
                                    md: 200,
                                    lg: 350,
                                    xl: 650,
                                  }}
                                  sx={{
                                    textDecoration: 'underline',
                                  }}
                                >
                                  {job.item?.title}
                                </Typography>
                              </Box>
                              <Typography variant="body2" color="textSecondary">
                                {job.item?.uniqueId}
                              </Typography>
                            </Box>
                          ) : (
                            <Box>
                              <Typography
                                variant="subtitle2"
                                noWrap
                                maxWidth={{
                                  md: 200,
                                  lg: 350,
                                  xl: 650,
                                }}
                                sx={{
                                  textDecoration: 'underline',
                                }}
                              >
                                Show in SKU
                              </Typography>
                            </Box>
                          )}
                        </Link>
                      </Box>
                    </Box>
                  </TableCell>

                  {/* SKU */}
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                      to={
                        job.item?.sku
                          ? `/tenants/${tenant?.id}/skus/${job.item?.sku?.id}/timeline?source=jobs#${job.id}`
                          : `/tenants/${tenant?.id}/skus/${job.sku?.id}/timeline?source=jobs#${job.id}`
                      }
                      underline="none"
                      variant="subtitle2"
                    >
                      <Typography
                        variant="body2"
                        color="inherit"
                        noWrap
                        maxWidth={{
                          md: 200,
                          lg: 350,
                          xl: 450,
                        }}
                        sx={{
                          textDecoration: 'underline',
                        }}
                      >
                        {job.item?.sku?.title || job.sku?.title || '-'}
                      </Typography>
                    </Link>
                  </TableCell>

                  {/* User */}
                  <TableCell>{job.user && <Box>{job.user.email}</Box>}</TableCell>

                  {/* Date Time */}
                  <TableCell>
                    <Box>
                      <Typography color="inherit" variant="body2">
                        {format(new Date(job.startedAt || job.createdAt), 'h:mmaaa MM/dd/yyyy')}
                      </Typography>
                    </Box>
                  </TableCell>

                  {/* Status */}
                  <TableCell>
                    <JobStatus job={job} />
                  </TableCell>

                  {/* Time */}
                  <TableCell align="right">
                    <Box>
                      <Typography color="inherit" variant="body2">
                        {Number.isFinite(job.time) ? `${(job.time / 1000).toFixed(2)} s` : '-'}
                      </Typography>
                    </Box>
                  </TableCell>

                  {/* Menu */}
                  <TableCell align="right" sx={{ pl: 0 }}>
                    <JobsMenu job={job} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          text="No activities in selected time period."
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={jobsCount}
      />
    </Box>
  );
};

JobsTable.defaultProps = {
  page: 1,
  jobs: [],
  jobsCount: 0,
  sort: 'desc',
  sortBy: 'createdAt',
};

JobsTable.propTypes = {
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  jobs: Proptypes.array,
  jobsCount: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
};
