import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import { LoadingButton } from '@material-ui/lab';

interface ConfirmationDialogProps {
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  confirmationInProgress?: boolean;
  open: boolean;
  title: string;
  variant: 'error' | 'warning' | 'info';
}

const icons = {
  error: <ErrorIcon color="error" fontSize="large" />,
  warning: <WarningIcon color="warning" fontSize="large" />,
  info: <InfoIcon color="info" fontSize="large" />,
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const { message, onCancel, onConfirm, open, title, variant, confirmationInProgress, ...other } =
    props;

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
        },
      }}
      {...other}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {icons[variant]}
        <Typography color="inherit" sx={{ ml: 2 }} variant="inherit">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography color="textPrimary" variant="body1">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel} variant="text" disabled={confirmationInProgress}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          onClick={onConfirm}
          variant="contained"
          loading={confirmationInProgress}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  open: false,
};

ConfirmationDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['error', 'warning', 'info']),
  confirmationInProgress: PropTypes.bool,
};
